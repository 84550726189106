import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'

import { convertToBase64 } from '../../../libs/files'
import { useUploadAfaCartMutation } from '../../../services/afaCart'
import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../style/theme'
import { CustomIconAndTextButton } from '../components/AfaCheckoutCommonComponents'
import FolderIcon from '../../../components/icons/FolderIcon'
import AfaCheckoutFileIcon from '../../../components/icons/AfaCheckoutFileIcon'
import CloseIcon from '../../../components/icons/CloseIcon'
import SuccessIcon from '../../../components/icons/SuccessIcon'
import TrashIcon from '../../../components/icons/TrashIcon'
import afaActions from '../../../store/afa/actions'
import { selectAfaFileData } from '../../../store/afa/selectors'
import { useHistory } from 'react-router-dom'
import { errorNotification } from '../../../components/Notification/notifications'

const BoxTitle = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  text-transform: uppercase;
  margin-bottom: ${getFluidSizeWithFullFormula('px', 48, 64, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    font-weight: bold;
    letter-spacing: 0.8px;
    color: ${palette.cloudBurst};
  }

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
    letter-spacing: 0.28px;
    color: ${palette.tangaroa};
  }
`

const ImportArea = styled.div`
  border: dashed ${palette.manatee};
  border-width: clamp(1px, ${getFluidSizeWithFullFormula('px', 1, 4, 1366, 3840)}, 4px);
  height: ${getFluidSizeWithFullFormula('px', 336, 602, 1366, 3840)};
  width: ${getFluidSizeWithFullFormula('px', 422, 1372, 1366, 3840)};
  max-width: 100%;
  border-radius: 8px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${palette.manatee};

  &.uploading {
    position: relative;
    border: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    background-color: ${palette.extraLight};
    padding-block: ${getFluidSizeWithFullFormula('px', 24, 96, 1366, 3840)};
    padding-inline: 32px;
    grid-row-gap: ${getFluidSizeWithFullFormula('px', 95, 142, 1366, 3840)};
  }

  &.success {
    grid-row-gap: ${getFluidSizeWithFullFormula('px', 52, 47, 1366, 3840)};
  }
`

const FolderIconStyled = styled(FolderIcon)`
  fill: transparent;
  stroke: ${palette.manatee};
  width: clamp(80px, ${getFluidSizeWithFullFormula('px', 80, 200, 1366, 3840)}, 200px);
  height: clamp(80px, ${getFluidSizeWithFullFormula('px', 80, 200, 1366, 3840)}, 200px);
  margin-bottom: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
`

const DragAndDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${palette.manatee};
  gap: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 24, 32, 1366, 3840)};
`

const DragAndDropText = styled.span`
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
`

const DragAndDropDividerText = styled.span`
  font-size: ${getFluidSizeWithFullFormula('px', 14, 18, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
  }
`

const StyledButton = styled(CustomIconAndTextButton)`
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  letter-spacing: 0.3px;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  padding-block: ${getFluidSizeWithFullFormula('px', 9, 27, 1366, 3840)};
  padding-inline: ${getFluidSizeWithFullFormula('px', 21, 24, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    line-height: 1.38;
  }

  @media (min-width: ${breakpoints.L}) {
    line-height: 0.75;
  }
`

const loadingKeyframe = keyframes`
  to {
      rotate: 360deg;
  }
`

const Loading = styled.div`
  position: relative;
  height: 100%;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: ${getFluidSizeWithFullFormula('px', 92, 200, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 92, 200, 1366, 3840)};
    translate: -50% 0;
    border-radius: 50%;
    border: clamp(8px, ${getFluidSizeWithFullFormula('px', 8, 20, 1366, 3840)}, 20px) solid
      ${palette.platinum};
    border-top-color: ${palette.tangaroa};
    animation: ${loadingKeyframe} 1.2s linear infinite;
  }
`

const UploadStateContainer = styled.div`
  display: flex;
  justify-content: center;
`

const UploadState = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 8px;
  border: 1px solid ${palette.tangaroa};
  border-radius: 4px;
  align-items: center;
  width: min(100%, ${pxToRem(357)}rem);
  height: ${getFluidSizeWithFullFormula('px', 40, 68, 1366, 3840)};
  padding-left: 12px;
  padding-right: ${getFluidSizeWithFullFormula('px', 16, 10, 1366, 3840)};
  background-color: ${palette.white};
`

const AfaCheckoutFileIconContainer = styled.div`
  svg {
    width: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  }

  width: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
`

const UploadFileName = styled.p`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  font-weight: bold;
  color: ${palette.tangaroa};
  line-height: normal;
  margin-top: 3px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const UploadCancelButton = styled.button`
  &.close {
    svg {
      width: ${getFluidSizeWithFullFormula('px', 16.215, 23.16, 1366, 3840)};
      height: ${getFluidSizeWithFullFormula('px', 16.215, 23.16, 1366, 3840)};
      fill: ${palette.tangaroa};
    }

    width: ${getFluidSizeWithFullFormula('px', 14, 20, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 14, 20, 1366, 3840)};
  }

  &.trash {
    svg {
      width: ${getFluidSizeWithFullFormula('px', 13.5, 19.3, 1366, 3840)};
      height: ${getFluidSizeWithFullFormula('px', 14, 20, 1366, 3840)};
    }

    width: ${getFluidSizeWithFullFormula('px', 13.5, 19.3, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 14, 20, 1366, 3840)};
  }

  cursor: pointer;
`

const SuccessIconContainer = styled.div`
  svg {
    width: ${getFluidSizeWithFullFormula('px', 88, 200, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 88, 200, 1366, 3840)};
  }

  width: ${getFluidSizeWithFullFormula('px', 88, 200, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 88, 200, 1366, 3840)};
`

const FailureIconContainer = styled.div`
  border-radius: 4rem;
  border: solid ${pxToRem(7)}rem ${palette.milanoRed};
  width: ${getFluidSizeWithFullFormula('px', 88, 200, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 88, 200, 1366, 3840)};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${palette.milanoRed};
    width: 60%;
    height: 60%;
  }
`

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  row-gap: ${getFluidSizeWithFullFormula('px', 26, 66, 1366, 3840)};
`

const ResultText = styled.p`
  font-family: 'Avenir-Heavy', sans-serif;
  font-size: clamp(14px, ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)}, 24px);
  color: ${palette.tangaroa};
  text-align: center;

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
    color: ${palette.cloudBurst};
  }
`

const Upload: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()

  const [uploadCart, uploadCartResult] = useUploadAfaCartMutation()
  const [defaultFile, setDefaultFile] = useState<File>()

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: ['.xls', '.xlsx', '.csv', '.xlt', '.xml'],
  })

  const fileData = useSelector(selectAfaFileData)

  useEffect(() => {
    if (fileData && history.location.state) {
      fetch(`data:text/plain;base64,${fileData?.File}`)
        .then(res => res.arrayBuffer())
        .then(arrayBuffer => {
          const file = new File([arrayBuffer], fileData.FileName)
          setDefaultFile(file)
        })
    }
  }, [fileData, history.location.state])

  const file: File | undefined = defaultFile || acceptedFiles[0]

  const uploadSucceeded =
    uploadCartResult.isSuccess &&
    !uploadCartResult.data?.fault &&
    !uploadCartResult.data?.message &&
    file

  const uploadFailed =
    uploadCartResult.isError || uploadCartResult.data?.fault || !!uploadCartResult.data?.message

  const uploadEnded = uploadSucceeded || uploadFailed

  const removeFile = () => {
    uploadCartResult.reset()
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
    dispatch(afaActions.setImportExportStep(0))
  }

  useEffect(() => {
    if (uploadFailed) {
      if (uploadCartResult.data?.message) {
        errorNotification({
          message: uploadCartResult.data.message,
        })
      }
      uploadCartResult.data?.items.forEach(({ fault, messageCode, messageVariables }) => {
        if (fault && messageCode) {
          errorNotification({
            message: t(`Errors.BackendCodes.${messageCode}`, {
              ...messageVariables,
              interpolation: { escapeValue: false },
            }),
          })
        }
      })
    }
  }, [t, uploadCartResult.data?.items, uploadCartResult.data?.message, uploadFailed])

  useEffect(() => {
    if (file) {
      convertToBase64(file).then((base64File: string) => {
        const fileData = {
          FileName: file.name,
          File: base64File.split('base64,')[1],
        }
        dispatch(afaActions.setFileData(fileData))
        uploadCart(true, fileData)
      })
    }
  }, [file, uploadCart, dispatch])

  useEffect(() => {
    if (uploadSucceeded) {
      dispatch(afaActions.setImportExportStep(1))
    }
  }, [dispatch, uploadSucceeded])

  return (
    <>
      <BoxTitle>
        <span>{t('Checkout.ImportExport.ImportTitle')}</span>
      </BoxTitle>

      {(uploadCartResult.isUninitialized || !file) && (
        <ImportArea {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <FolderIconStyled />
          <DragAndDropContainer>
            <DragAndDropText>{t('Checkout.ImportExport.DragAndDropAfa')}</DragAndDropText>
            <DragAndDropDividerText>{t('Checkout.ImportExport.Divider')}</DragAndDropDividerText>
          </DragAndDropContainer>
          <StyledButton text={t('Checkout.ImportExport.Upload')} type="button" onClick={open} />
        </ImportArea>
      )}

      {uploadCartResult.isLoading && file && (
        <ImportArea className="uploading">
          <Loading />

          <UploadStateContainer>
            <UploadState>
              <AfaCheckoutFileIconContainer>
                <AfaCheckoutFileIcon />
              </AfaCheckoutFileIconContainer>

              <UploadFileName>{file.name}</UploadFileName>
              <UploadCancelButton className="close" onClick={removeFile}>
                <CloseIcon />
              </UploadCancelButton>
            </UploadState>
          </UploadStateContainer>
        </ImportArea>
      )}

      {uploadEnded && (
        <ImportArea className="uploading success">
          <ResultWrapper>
            {uploadSucceeded && (
              <>
                <SuccessIconContainer>
                  <SuccessIcon />
                </SuccessIconContainer>
                <ResultText>{t('Checkout.ImportExport.AfaComplete')}</ResultText>
              </>
            )}
            {uploadFailed && (
              <>
                <FailureIconContainer>
                  <CloseIcon />
                </FailureIconContainer>
                <ResultText>
                  {t('Checkout.ImportExport.Failed')}
                  {!!uploadCartResult.data?.message && <div>{uploadCartResult.data?.message}</div>}
                </ResultText>
              </>
            )}
          </ResultWrapper>

          <UploadStateContainer>
            <UploadState>
              <AfaCheckoutFileIconContainer>
                <AfaCheckoutFileIcon />
              </AfaCheckoutFileIconContainer>

              <UploadFileName>{file.name}</UploadFileName>
              <UploadCancelButton className="trash" onClick={removeFile}>
                <TrashIcon />
              </UploadCancelButton>
            </UploadState>
          </UploadStateContainer>
        </ImportArea>
      )}
    </>
  )
}

export default Upload
