import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import WarningIcon from '../../../components/icons/WarningIcon'

import { CardDeltaOperation } from '../../../model/cart'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../style/theme'
import Header from '../../../components/Header'
import {
  NextButton,
  StyledTableTh,
  StyledTableTitle,
  StyledTableWrapper,
} from '../components/AfaCheckoutCommonComponents'
import RCIcon from '../../../components/UI/RCIcon'
import { useUploadAfaCartMutation } from '../../../services/afaCart'
import AfaSubHeader from '../components/AfaSubHeader'
import AfaFooter from '../../../components/AfaFooter'
import { useDispatch, useSelector } from 'react-redux'
import { selectAfaFileData } from '../../../store/afa/selectors'
import AfaModal from '../../../components/AfaModal'
import { useHistory } from 'react-router-dom'
import Loading from '../../../components/Loading'
import {
  errorNotification,
  successNotification,
} from '../../../components/Notification/notifications'
import { getDeviceAbsolutePath } from '../../../libs/url'
import { useParams } from 'react-router'
import afaActions from '../../../store/afa/actions'

const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100%;

  @media (min-width: ${breakpoints.L}) {
    grid-template-rows: auto 1fr auto auto;

    header {
      order: 1;
    }

    section {
      order: 2;
    }

    footer {
      order: 5;
    }
  }
`

const Content = styled.section`
  background-color: ${palette.lightGray};
  color: ${palette.tangaroa};
  padding-top: ${gF('px', 35, 80, 1366, 3840)};
  padding-bottom: ${gF('px', 35, 80, 1366, 3840)};
  padding-left: ${gF('px', 32, 64, 1366, 3840)};
  padding-right: ${gF('px', 32, 64, 1366, 3840)};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
`

const TableContainer = styled.div`
  background-color: ${palette.white};
  overflow-y: auto;
`

const Title = styled.div`
  font-size: ${gF('px', 24, 64, 1366, 3840)};
  font-weight: bold;
  line-height: normal;
  margin-bottom: ${gF('px', 37, 80, 1366, 3840)};
`

const TableTitle = styled(StyledTableTitle)`
  padding-left: ${gF('px', 35, 135, 1366, 3840)};
`

const TableWrapper = styled(StyledTableWrapper)`
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  border-inline: 1px solid ${palette.platinum};
  text-align: center;

  tr {
    border-bottom: 1px solid ${palette.platinum};

    @media (min-width: ${breakpoints.L}) {
      border-bottom-width: 2px;
    }

    &.delete,
    &.error {
      td:first-of-type,
      td:nth-of-type(2) {
        text-decoration: line-through;
      }
    }
  }

  td {
    position: relative;

    &.color {
      color: ${palette.tree};
    }

    &.colorBlue {
      color: ${palette.greyBlue};
    }

    & > .arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      font-size: ${getFluidFontSize('20px')};
    }
  }

  table {
    width: 100%;
  }
`

export const ComparisonTableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`

const BadgeSpan = styled.span`
  width: fit-content;
  margin: 0 auto;
  letter-spacing: 0.3px;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  padding-inline: ${gF('px', 18, 24, 1366, 3840)};
  padding-block: ${gF('px', 8, 12, 1366, 3840)};
  border-radius: ${gF('px', 16, 58, 1366, 3840)};
  border: 1.5px solid;
  font-weight: bold;
`

const AddBadge = styled(BadgeSpan)`
  border-color: ${palette.darkGreen};
  color: ${palette.darkGreen};
`

const RemoveBadge = styled(BadgeSpan)`
  border-color: ${palette.pomegranate};
  color: ${palette.pomegranate};
`

const EditBadge = styled(BadgeSpan)`
  border-color: ${palette.shadowBlue};
  color: ${palette.shadowBlue};
`

const BadgeError = styled.span<{
  error: boolean | null
}>`
  color: ${palette.pomegranate};

  svg {
    color: ${({ error }) => (error ? palette.pomegranate : palette.mango)};
    display: inline;
  }
`

const TableTh = styled(StyledTableTh)`
  font-size: ${gF('px', 12, 24, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    letter-spacing: 0.7px;
  }
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${palette.tangaroa};
`

const ModalTitle = styled.h2`
  font-family: GilmerBold, sans-serif;
  text-align: center;
  font-size: ${gF('px', 26, 48, 1366, 3840)};
`

const ModalText = styled.p`
  font-family: GilmerMedium, sans-serif;
  text-align: center;
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  margin: ${pxToRem(72)}rem 0;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${gF('px', 40, 64, 1366, 3840)};
  margin-top: ${gF('px', 12, 65, 1366, 3840)};
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  padding: ${gF('px', 20, 27, 1366, 3840)} ${gF('px', 20, 24, 1366, 3840)};
  height: ${gF('px', 56, 72, 1366, 3840)};
  min-width: ${gF('px', 120, 150, 1366, 3840)};
  font-weight: bold;
`

const CancelButton = styled(Button)`
  color: ${palette.tangaroa};
  border: 1px solid ${palette.tangaroa};
  background-color: ${palette.white};

  @media (min-width: ${breakpointsCross.L.min}) {
    border: 2px solid ${palette.tangaroa};
  }
`

const DoneButton = styled(Button)`
  color: ${palette.white};
  background-color: ${palette.tangaroa};
`

function TableItem({
  doorId,
  skuCode,
  actquant,
  newquant,
  newreference,
  actdelivery,
  newdelivery,
  fault,
  messageVariables,
  messageCode,
}: CardDeltaOperation) {
  const { t } = useTranslation()

  const isDelete = actquant > 0 && newquant == 0

  const actionBadge = (
    prevQt: CardDeltaOperation['actquant'],
    newQt: CardDeltaOperation['newquant'],
    fault: CardDeltaOperation['fault'],
    messageCode: CardDeltaOperation['messageCode'],
    messageVariables: CardDeltaOperation['messageVariables'],
  ) => {
    if (fault || messageCode) {
      return (
        <BadgeError error={fault}>
          <WarningIcon />{' '}
          {messageCode
            ? t(`Errors.BackendCodes.${messageCode}`, {
                ...messageVariables,
                interpolation: { escapeValue: false },
              })
            : t('Errors.parsingUploadedCartRow')}
        </BadgeError>
      )
    }

    if (prevQt == 0 && newQt > 0) {
      return <AddBadge>{t('Checkout.ImportExport.AddItem')}</AddBadge>
    } else if (prevQt > 0 && newQt == 0) {
      return <RemoveBadge>{t('Checkout.ImportExport.RemoveItem')}</RemoveBadge>
    } else if (prevQt != 0 && newQt != 0) {
      return <EditBadge>{t('Checkout.ImportExport.EditItem')}</EditBadge>
    }
  }

  return (
    <tr className={[isDelete ? 'delete' : '', fault ? 'error' : ''].join(' ')}>
      <td>{doorId}</td>
      <td>{skuCode}</td>
      <td colSpan={fault ? 7 : 1} style={{ textAlign: fault ? 'left' : 'center' }}>
        {actionBadge(actquant, newquant, fault, messageCode, messageVariables)}
      </td>
      {!fault && (
        <>
          <td className="colorBlue">
            {actquant}
            <span className="arrow">
              <RCIcon type="checkout-import-export-comparison-arrow" />
            </span>
          </td>
          <td className="color">{newquant}</td>
          <td>{newreference}</td>
          <td className="colorBlue">
            {actdelivery}
            <span className="arrow">
              {(!!actdelivery || !!newdelivery) && (
                <RCIcon type="checkout-import-export-comparison-arrow" />
              )}
            </span>
          </td>
          <td>{newdelivery}</td>
        </>
      )}
    </tr>
  )
}

const dataChangedOrError = ({
  actdelivery,
  newdelivery,
  actreference,
  newreference,
  actquant,
  newquant,
  fault,
  message,
}: CardDeltaOperation) => {
  const deliveryChanged = actdelivery !== newdelivery
  const referenceChanged = actreference !== newreference
  const quantityChanged = actquant !== newquant
  return deliveryChanged || referenceChanged || quantityChanged || fault || !!message
}

const Comparison: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const file = useSelector(selectAfaFileData)
  const [open, setOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const history = useHistory()
  const { assortment } = useParams<{ assortment: string }>()

  const [uploadCart, uploadCartResult] = useUploadAfaCartMutation()

  const tableHeader: [string, keyof CardDeltaOperation | 'action'][] = [
    [t('Checkout.ImportExport.DoorId'), 'doorId'],
    [t('Checkout.ImportExport.ModelCode'), 'skuCode'],
    [t('Checkout.ImportExport.Action'), 'action'],
    [t('Checkout.ImportExport.PrevQty'), 'actquant'],
    [t('Checkout.ImportExport.NewQty'), 'newquant'],
    [t('Checkout.ImportExport.RefNumber'), 'newreference'],
    [t('Checkout.ImportExport.PrevDate'), 'actdelivery'],
    [t('Checkout.ImportExport.DeliveryDate'), 'newdelivery'],
  ]

  const uploadFault = uploadCartResult.data?.fault

  useEffect(() => {
    if (uploadFault) {
      errorNotification({
        message: t('Checkout.ImportExport.BlockingErrorsInUploadedFile'),
      })
    }
  }, [uploadFault, t])

  const uploadFile = () => {
    if (file) {
      setUploading(true)
      setOpen(false)
      uploadCart(false, file)
    }
  }

  useEffect(() => {
    if (uploadCartResult.status === 'fulfilled' && uploading) {
      setUploading(false)
      successNotification({
        message: t('Afa.Cart.cartUpdated'),
        className: 'afa-checkout-success-email-notification',
        useAsAfa: true,
      })
      uploadCartResult.reset()
      dispatch(afaActions.setImportExportStep(0))
      history.push(`${getDeviceAbsolutePath()}/${assortment}/cart`)
    }
  }, [assortment, uploadCartResult, uploading, t, history, dispatch])

  const sortedItems = uploadCartResult.data?.items.filter(dataChangedOrError).sort((a, b) => {
    return (a.message || '').localeCompare(b.message || '') * -1
  })

  const showLoader = uploadCartResult.status === 'pending'

  return (
    <>
      <Wrapper>
        <Header hideButtons />
        <AfaSubHeader activeLink="import-export" />

        <Content>
          <Title>{t('Afa.Checkout.ImportExportCart')}</Title>
          <TableContainer>
            <TableTitle>{t('Checkout.ImportExport.Comparison')}</TableTitle>
            <TableWrapper>
              <table>
                <ComparisonTableHead>
                  <tr>
                    {tableHeader.map((column, index) => {
                      const columnName: string = column[0].toString()
                      return <TableTh key={index}>{columnName}</TableTh>
                    })}
                  </tr>
                </ComparisonTableHead>
                <tbody>
                  {sortedItems?.map(deltaResult => {
                    return <TableItem key={deltaResult.upc} {...deltaResult} />
                  })}
                </tbody>
              </table>
            </TableWrapper>
          </TableContainer>
        </Content>
        <AfaFooter
          centralColContent={
            <NextButton onClick={() => setOpen(true)}>{t('Checkout.Stepper.Confirm')}</NextButton>
          }
        />
        <AfaModal fullscreen={false} isOpen={open} onRequestClose={() => setOpen(false)}>
          <ModalWrapper>
            <ModalTitle>{t('Afa.Cart.importCartTitle')}</ModalTitle>
            <ModalText>{t('Afa.Cart.importCartMessage')}</ModalText>
            <Buttons>
              <CancelButton onClick={() => setOpen(false)}>
                {t('Afa.Cart.Adjust.Cancel')}
              </CancelButton>
              <DoneButton onClick={uploadFile}>{t('Afa.Cart.confirmChanges')}</DoneButton>
            </Buttons>
          </ModalWrapper>
        </AfaModal>
        {showLoader && <Loading isFullPage />}
      </Wrapper>
    </>
  )
}

export default Comparison
