import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { breakpointsCross, getFluidSizeWithFullFormula, palette, pxToRem } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { afaCartProductDetailsSelector, afaCartDataSelector } from '../../store/afaCart/selectors'
import { AfaCartProduct } from '../../model/afa'
import { useSearchParams } from '../../hooks/useSearchParams'
import AfaPdpSelectedColorRecap from '../AfaPdp/AfaPdpSelectedColorRecap'
import ColorDoorsAndDrops from '../AfaPdp/ColorDoorsAndDrops'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import { afaCartAdjustSelectors } from '../../store/afaCartAdjust/selectors'
import {
  useAdjustQuantitiesMutation,
  useUpdateAfaCartProductsMutation,
} from '../../services/afaCart'
import AfaModal from '../../components/AfaModal'
import afaActions from '../../store/afa/actions'
import AfaCartAdjustAll from './AfaCartAdjustAll/AfaCartAdjustAll'
import {
  cartAdjustAllKeysSelector,
  cartAdjustAllModalOpenSelector,
} from '../../store/afa/selectors'
import AfaCartAdjustAllNotNeeded from './AfaCartAdjustAll/AfaCartAdjustAllNotNeeded'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'
import { errorNotification, successNotification } from '../../components/Notification/notifications'
import useAfaGetCartUnavailableKeys from '../../hooks/useAfaGetCartUnavailableKeys'
import Loading from '../../components/Loading'
import AfaCartAdjustExpiredRecap from './AfaCartAdjustExpiredRecap'
import { getProductImageUrl } from '../../libs/productImages'
import { AfaCartAdjustType } from '../../store/afaCartAdjust/slice'
import AfaRemoveExpiredItemsModal from '../AfaRemoveExpiredItemsModal'

const gfs = getFluidSizeWithFullFormula

const ImageWrapper = styled.div`
  aspect-ratio: 1;
  background-color: white;
  opacity: 0.5;
  height: ${gfs('px', 98, 206, 1366, 3840)};

  &.active {
    opacity: 1;
    border: 2px solid ${palette.shadowBlue};
    border-right: none;
    border-top-left-radius: ${pxToRem(4)}rem;
    border-bottom-left-radius: ${pxToRem(4)}rem;
    height: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

type Props = {
  cartProduct: AfaCartProduct
}
const AfaCartAdjustColor: React.FC<Props> = ({ cartProduct }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const productDetails = useSelector(afaCartProductDetailsSelector(cartProduct.modelCode))

  const productDetailsColor = Object.values(productDetails?.mocos || {}).find(
    ({ colorCode }) => colorCode === cartProduct.colorCode,
  )

  const selectedModelCode = searchParams.get('model') || ''
  const selectedColorCode = searchParams.get('color') || ''

  const setSelectedMoco = useCallback(
    (modelCode: string, colorCode: string) => {
      searchParams.set('model', modelCode)
      searchParams.set('color', colorCode)
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  return productDetailsColor ? (
    <ImageWrapper
      className={
        selectedColorCode === productDetailsColor.colorCode &&
        selectedModelCode === productDetailsColor.modelCode
          ? 'active'
          : ''
      }
    >
      <Img
        src={getProductImageUrl({ path: productDetailsColor.catalogImgPath, imwidth: 100 })}
        alt=""
        onClick={() =>
          setSelectedMoco(productDetailsColor.modelCode, productDetailsColor.colorCode)
        }
      />
    </ImageWrapper>
  ) : (
    <></>
  )
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: block;
  padding: 0 ${gfs('px', 32, 64, 1366, 3840)};
  padding-bottom: ${gfs('px', 32, 90, 1366, 3840)};
  background-color: #f8f7f7;
  overflow: hidden;
  height: 100vh;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gfs('px', 7, 12, 1366, 3840)};
  margin-top: ${gfs('px', 68, 160, 1366, 3840)};
  margin-bottom: ${gfs('px', 24, 43, 1366, 3840)};

  &.manage-quantities {
    margin-bottom: ${gfs('px', 53, 93, 1366, 3840)};
  }
`

const TitleWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled.span`
  color: ${palette.tangaroa};
  font-size: ${gfs('px', 24, 64, 1366, 3840)};
  font-family: GilmerBold, sans-serif;

  &:first-letter {
    text-transform: capitalize;
  }
`

const AdjustAllButton = styled.div`
  cursor: pointer;
  font-weight: bold;
  background-color: ${palette.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  box-shadow: 0 4px 4px 0 rgba(3, 20, 52, 0.17);
  border-radius: 10rem;
  padding: ${gfs('px', 9, 27, 1366, 3840)} ${gfs('px', 21, 32, 1366, 3840)};
  color: ${palette.tangaroa};
  font-size: ${gfs('px', 13, 24, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
`

const Subtitle = styled.span`
  color: #9a9fad;
  font-size: ${gfs('px', 18, 32, 1366, 3840)};
  font-weight: 500;
`

const Main = styled.main`
  flex-grow: 1;
  display: grid;
  overflow: hidden;
  grid-auto-rows: max-content max-content 1fr max-content;
  height: 100%;
`

const Colors = styled.div`
  display: grid;
  grid-template-columns: ${getFluidSizeWithFullFormula('px', 96, 202, 1366, 3840)} auto min-content;
  height: 100%;
  overflow: hidden;
`

const ColorImagesWrapper = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-auto-rows: min-content;
  gap: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`

const ColorDoorsAndDropsWithMargin = styled(ColorDoorsAndDrops)`
  margin-top: 1.6rem;
  margin-left: 1.5rem;
  @media screen and (max-width: ${breakpointsCross.L.max}) {
    margin-top: 2rem;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${gfs('px', 40, 64, 1366, 3840)};
  margin-top: ${gfs('px', 12, 65, 1366, 3840)};
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${gfs('px', 13, 24, 1366, 3840)};
  padding: ${gfs('px', 20, 27, 1366, 3840)} ${gfs('px', 20, 24, 1366, 3840)};
  height: ${gfs('px', 56, 72, 1366, 3840)};
  min-width: ${gfs('px', 120, 150, 1366, 3840)};
  font-weight: bold;
`

const CancelButton = styled(Button)`
  color: ${palette.tangaroa};
  border: 1px solid ${palette.tangaroa};
  background-color: ${palette.white};
  font-family: GilmerBold, sans-serif;

  @media (min-width: ${breakpointsCross.L.min}) {
    border: 2px solid ${palette.tangaroa};
  }
`

const DoneButton = styled(Button)<{
  disabled?: boolean
}>`
  color: ${palette.white};
  background-color: ${({ disabled }) => (disabled ? palette.philippineSilver : palette.tangaroa)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-family: GilmerBold, sans-serif;
`

const SelectedColorData = styled.div`
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  display: flex;
`

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${palette.tangaroa};

  & > span:last-of-type {
    margin-bottom: ${gfs('px', 70, 670, 1366, 3840)};
  }
`

const WarningTitle = styled.span`
  font-size: ${gfs('px', 24, 48, 1366, 3840)};
  font-weight: bold;
  margin-bottom: ${pxToRem(72)}rem;

  @media (min-width: ${breakpointsCross.L.min}) {
    margin-bottom: ${pxToRem(40)}rem;
  }
`

const WarningText = styled.span`
  font-size: ${gfs('px', 20, 40, 1366, 3840)};
`

const SelectedColorWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content 1fr;
  width: 100%;
`

const getModalTitle = (adjustType: AfaCartAdjustType | false) => {
  switch (adjustType) {
    case AfaCartAdjustType.OUT_OF_STOCK:
      return 'Afa.Cart.Unavailable.OutOfStock'
    case AfaCartAdjustType.EXPIRED_DROPS:
      return 'Afa.Cart.Expired.Title'
    case AfaCartAdjustType.MANAGE_QUANTITIES:
      return 'Afa.Cart.ManageQuantities'
    default:
      return ''
  }
}

const AfaCartAdjust: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedModelCode = searchParams.get('model') || ''
  const selectedColorCode = searchParams.get('color') || ''

  const [updateAfaCartProducts, updateAfaCartProductsResult] = useUpdateAfaCartProductsMutation()
  const [warningUnfinishedOpen, setWarningUnfinishedOpen] = useState(false)
  const [adjustAllNotNeededWarning, setAdjustAllNotNeededWarning] = useState(false)
  const [expiredRecapOpen, setExpiredRecapOpen] = useState(false)
  const [isUpdatingCart, setIsUpdatingCart] = useState(false)

  const checkAdjustCartOnDoorModalOpen = useSelector(cartAdjustAllModalOpenSelector)
  const productDetails = useSelector(afaCartProductDetailsSelector(selectedModelCode))
  const isCartAdjustOpen = useSelector(afaCartAdjustSelectors.isOpen)
  const isEdited = useSelector(afaCartAdjustSelectors.isEdited)
  const removedKeys = useSelector(afaCartAdjustSelectors.removedKeys)
  const cartProducts = useSelector(afaCartAdjustSelectors.cartProducts)
  const expiredCartProductsToRemove = useSelector(
    afaCartAdjustSelectors.expiredCartProductsToRemove,
  )

  const { expiredDropsCartProducts } = useGetExpiredCartProducts({ cartProducts })
  const { checkUnavailableCartProducts } = useAfaGetCartUnavailableKeys(cartProducts)

  const cartData = useSelector(afaCartDataSelector)

  const keysToAdjust = useSelector(cartAdjustAllKeysSelector)

  const [adjustQuantities] = useAdjustQuantitiesMutation({
    cacheKey: 'adjustAll',
  })

  const uniqueCartProducts = useMemo(() => {
    return cartProducts.filter(
      ({ modelCode, colorCode }, i) =>
        cartProducts.findIndex(
          cartProduct => cartProduct.modelCode === modelCode && cartProduct.colorCode === colorCode,
        ) === i,
    )
  }, [cartProducts])

  const uniqueCartProductsWithDetailsColor = uniqueCartProducts.filter(cartProduct => {
    const productDetails = cartData?.[cartProduct.modelCode]
    const productDetailsColor = Object.values(productDetails?.mocos || {}).find(
      ({ colorCode }) => colorCode === cartProduct.colorCode,
    )
    return productDetailsColor
  })

  const uniqueUpcs = useMemo(() => {
    return [...new Set(cartProducts.map(({ upc }) => upc))]
  }, [cartProducts])

  useEffect(() => {
    if (
      (!selectedModelCode || !selectedColorCode) &&
      uniqueCartProductsWithDetailsColor.length > 0
    ) {
      searchParams.set('model', uniqueCartProductsWithDetailsColor[0].modelCode)
      searchParams.set('color', uniqueCartProductsWithDetailsColor[0].colorCode)

      setSearchParams(searchParams, { replace: true })
    }
  }, [
    selectedModelCode,
    selectedColorCode,
    uniqueCartProductsWithDetailsColor,
    searchParams,
    setSearchParams,
  ])

  const productDetailsColor = Object.values(productDetails?.mocos || {}).find(
    ({ colorCode }) => colorCode === selectedColorCode,
  )

  const resetQueryParams = useCallback(() => {
    searchParams.delete('model')
    searchParams.delete('color')
    setSearchParams(searchParams, { replace: true })
  }, [searchParams, setSearchParams])

  const disableDoneButton = !isEdited && isCartAdjustOpen !== AfaCartAdjustType.EXPIRED_DROPS

  const [, setNeedNotificationStatusCheck] = useState(false) // probably can be removed

  const handleSave = useCallback(
    (expiredRecapConfirmed?: boolean) => {
      setNeedNotificationStatusCheck(true)

      if (disableDoneButton) {
        return
      }

      if (expiredDropsCartProducts.length > 0 && !expiredRecapConfirmed) {
        setExpiredRecapOpen(true)
        return
      }

      const unavailableUpcs = checkUnavailableCartProducts(cartProducts)
      if (unavailableUpcs.length > 0 && !expiredRecapConfirmed) {
        setWarningUnfinishedOpen(true)
        return
      }

      setIsUpdatingCart(true)
      updateAfaCartProducts(
        cartProducts
          .filter(({ key }) => !removedKeys.includes(key))
          .map(cartProduct => {
            const quantity = expiredDropsCartProducts.find(eP => eP.key === cartProduct.key)
              ? 0
              : cartProduct.unconfirmedQuantity
            return {
              ...cartProduct,
              quantity,
            }
          }),
      )
    },
    [
      disableDoneButton,
      checkUnavailableCartProducts,
      cartProducts,
      expiredDropsCartProducts,
      updateAfaCartProducts,
      removedKeys,
    ],
  )

  useEffect(() => {
    if (isUpdatingCart && updateAfaCartProductsResult.status === 'fulfilled') {
      setIsUpdatingCart(false)
      dispatch(afaCartAdjustActions.reset())
      resetQueryParams()
      setNeedNotificationStatusCheck(false)
      if (updateAfaCartProductsResult.isError || updateAfaCartProductsResult.error) {
        errorNotification({
          message: t('Afa.errorTryingUpdateCart'),
        })
      }
      if (updateAfaCartProductsResult.isSuccess && updateAfaCartProductsResult.data?.result) {
        successNotification({
          message: t('Afa.Cart.Adjust.Success'),
        })
      }
      if (updateAfaCartProductsResult.data?.warnings.length) {
        updateAfaCartProductsResult.data?.warnings.forEach(({ messageCode }) => {
          errorNotification({
            message: t('Errors.BackendCodes.Technical', {
              error_code: messageCode,
              interpolation: { escapeValue: false },
            }),
            duration: false,
          })
        })
      }
      updateAfaCartProductsResult.reset()
    }
  }, [dispatch, resetQueryParams, t, updateAfaCartProductsResult, isUpdatingCart])

  const handleOpenAdjustAll = useCallback(() => {
    const unavailableUpcs = checkUnavailableCartProducts(cartProducts)
    if (unavailableUpcs.length > 0) {
      dispatch(afaActions.toggleCartAdjustAllModalOpen())
      adjustQuantities({ simulate: true, keys: keysToAdjust })
      dispatch(afaActions.setAdjustAllIsSimulate(true))
    } else {
      setAdjustAllNotNeededWarning(true)
    }
  }, [adjustQuantities, cartProducts, checkUnavailableCartProducts, dispatch, keysToAdjust])

  const allKeys = useMemo(() => {
    return [...new Set(cartProducts.map(({ key }) => key))]
  }, [cartProducts])

  const selectedCartProduct = uniqueCartProducts.find(
    ({ modelCode, colorCode }) =>
      modelCode === selectedModelCode && colorCode === selectedColorCode,
  )

  return (
    <Wrapper>
      <Main>
        <Header
          className={
            isCartAdjustOpen !== AfaCartAdjustType.OUT_OF_STOCK ? 'manage-quantities' : undefined
          }
        >
          <TitleWithButton>
            <Title>{t(getModalTitle(isCartAdjustOpen))}</Title>
            {isCartAdjustOpen === AfaCartAdjustType.OUT_OF_STOCK && (
              <AdjustAllButton onClick={handleOpenAdjustAll}>
                {t('Afa.Cart.AdjustAll.CTA')}
              </AdjustAllButton>
            )}
          </TitleWithButton>
          {isCartAdjustOpen === AfaCartAdjustType.OUT_OF_STOCK && (
            <Subtitle>
              {uniqueUpcs.length} {t('Afa.Cart.Adjust.Items')}
            </Subtitle>
          )}
        </Header>

        {productDetails && productDetailsColor && selectedCartProduct && (
          <SelectedColorWrapper>
            <AfaCartAdjustColor cartProduct={selectedCartProduct} />
            <AfaPdpSelectedColorRecap
              modelCode={selectedModelCode}
              name={productDetails.name}
              selectedColor={productDetailsColor}
            />
          </SelectedColorWrapper>
        )}

        <Colors>
          <ColorImagesWrapper>
            {uniqueCartProducts
              .filter(({ key }) => key !== selectedCartProduct?.key)
              .slice()
              .sort((a, b) => a.modelCode.localeCompare(b.modelCode))
              .map(cartProduct => {
                return <AfaCartAdjustColor cartProduct={cartProduct} key={cartProduct.key} />
              })}
          </ColorImagesWrapper>
          <SelectedColorData>
            {productDetailsColor && (
              <ColorDoorsAndDropsWithMargin
                cartIsLoadingUpdates={updateAfaCartProductsResult.isLoading}
                color={productDetailsColor}
              />
            )}
          </SelectedColorData>
        </Colors>

        <Buttons>
          <CancelButton
            onClick={() => {
              resetQueryParams()
              dispatch(afaCartAdjustActions.reset())
            }}
          >
            {t('Afa.Cart.Adjust.Cancel')}
          </CancelButton>
          <DoneButton disabled={disableDoneButton} onClick={() => handleSave()}>
            {t(
              isCartAdjustOpen === AfaCartAdjustType.EXPIRED_DROPS
                ? 'Afa.Cart.Adjust.Confirm'
                : 'Afa.Cart.Adjust.Done',
            )}
          </DoneButton>
        </Buttons>
      </Main>

      <AfaModal
        isOpen={warningUnfinishedOpen}
        onRequestClose={() => {
          setNeedNotificationStatusCheck(false)
          setWarningUnfinishedOpen(false)
        }}
        fullscreen={false}
      >
        <WarningWrapper>
          <WarningTitle>{t('Afa.Cart.Adjust.WarningTitle')}</WarningTitle>
          <WarningText>{t('Afa.Cart.Adjust.WarningText1')}</WarningText>
          <WarningText>{t('Afa.Cart.Adjust.WarningText2')}</WarningText>
          <Buttons>
            <CancelButton
              onClick={() => {
                setNeedNotificationStatusCheck(false)
                setWarningUnfinishedOpen(false)
              }}
            >
              {t('Afa.Cart.Adjust.WarningReview')}
            </CancelButton>
            <DoneButton
              onClick={() => {
                setWarningUnfinishedOpen(false)
                dispatch(afaActions.setCartAdjustAllKeys(allKeys))
                dispatch(afaActions.toggleCartAdjustAllModalOpen())
              }}
            >
              {t('Afa.Cart.Adjust.WarningAdjustAll')}
            </DoneButton>
          </Buttons>
        </WarningWrapper>
      </AfaModal>

      <AfaModal
        isOpen={checkAdjustCartOnDoorModalOpen}
        onRequestClose={() => {
          dispatch(afaActions.toggleCartAdjustAllModalOpen())
        }}
        fullscreen={false}
      >
        <AfaCartAdjustAll />
      </AfaModal>

      <AfaModal
        isOpen={adjustAllNotNeededWarning}
        onRequestClose={() => setAdjustAllNotNeededWarning(false)}
        fullscreen={false}
      >
        <AfaCartAdjustAllNotNeeded onClose={() => setAdjustAllNotNeededWarning(false)} />
      </AfaModal>

      <AfaModal
        isOpen={expiredRecapOpen}
        onRequestClose={() => {
          setNeedNotificationStatusCheck(false)
          setExpiredRecapOpen(false)
        }}
      >
        <AfaCartAdjustExpiredRecap
          expiredProducts={expiredDropsCartProducts}
          cartProducts={cartProducts}
          onConfirm={() => {
            setExpiredRecapOpen(false)
            handleSave(true)
          }}
        />
      </AfaModal>

      <AfaModal
        isOpen={expiredCartProductsToRemove.length > 0}
        onRequestClose={() => dispatch(afaCartAdjustActions.setCartExpiredProductsToRemove([]))}
      >
        <AfaRemoveExpiredItemsModal />
      </AfaModal>

      {updateAfaCartProductsResult.isLoading && <Loading />}
    </Wrapper>
  )
}

export default AfaCartAdjust
