import { UpdateCartPayload } from '../hooks/useUpdateCart'
import { AfaCartProduct } from '../model/afa'
import { Brand } from '../model/brand'
import { CartProduct, MocoInCart } from '../model/cart'
import { DdMmYyyyDateString } from '../model/model'
import { Moco, Product, Size } from '../model/product'
import { StarsResponse } from '../model/stars'
import { Warning } from '../model/warnings'
import { getBrandByBrandCode } from './brand'
import log from './log'
import { convertDdMmYyyyToDate } from './time'
import { errorNotification } from '../components/Notification/notifications'
import i18next from 'i18next'

/** Displays notifications when some actions went wrong on cart API
 *
 * Each warning message is an object with this properties:
 *
 * skuCode
 * upc
 * message
 * door
 * itemId
 *
 * @param {array} warnings a list of messages
 */
export const showCartWarnings = (warnings: Warning[]) => {
  warnings
    .reduce((result, warning) => {
      const isDuplicate = result.find(({ skuCode }) => skuCode === warning.skuCode)
      return isDuplicate ? result : result.concat(warning)
    }, [] as Warning[])
    .forEach((warning: Warning) => {
      log.error(warning)
      errorNotification({
        message: i18next.t('Errors.BackendCodes.Technical', {
          error_code: warning.messageCode,
          interpolation: { escapeValue: false },
        }),
        duration: false,
      })
    })
}

/**
 * Searches cart product item by moco
 * @param {array} products Cart products
 * @param {string} mocoCode Product moco
 * @returns {object|null} Cart product or null if not found
 */
export const getCartProductByMoco = (products: CartProduct[] = [], mocoCode: string) => {
  return products.find(cartProduct => cartProduct.mocoCode === mocoCode) || null
}

/**
 * Creates an object for keeping cart data related to brand
 * @param {object} brand
 * @returns {object}
 */
export const createBrandStatObject = (brand: Brand) => ({
  numberOfItems: 0,
  amount: 0,
  brand,
})

export const getBrandsFromAssortment = (starsAssortment: StarsResponse, brands: Brand[]) => {
  const starProducts = Object.values(starsAssortment).flatMap(({ upcs }) => upcs)

  const cartBrands = starProducts.reduce(
    (result, product) => {
      const brand = getBrandByBrandCode(brands, product.brandCode)

      if (!brand) {
        return result
      }

      const brandCode = brand.code
      const { modelCode, colorCode } = product
      const productDetails = starsAssortment[brandCode]?.productDetails[modelCode]?.mocos[colorCode]
      const whsPrice = (productDetails && productDetails.whsPrice) || 0

      const amount = whsPrice * 0

      // productDatails may not be loaded yet
      const category = productDetails
        ? productDetails.categoryId === '2'
          ? 'sun'
          : 'optical'
        : 'unkown'

      if (!result[brandCode]) {
        result[brandCode] = {
          brand,
          numberOfItems: 1,
          amount: 1,
          amountByCategory: {
            sun: 0,
            optical: 0,
            [category]: 0,
          },
          numberOfItemsByCategory: {
            sun: 0,
            optical: 0,
            [category]: 1,
          },
        }
      } else {
        result[brandCode].numberOfItems += 1
        result[brandCode].amount += amount
        result[brandCode].amountByCategory[category] += amount
        result[brandCode].numberOfItemsByCategory[category] += 1
      }

      return result
    },
    {} as {
      [key: string]: {
        numberOfItems: number
        brand: Brand
        numberOfItemsByCategory: { [key: string]: number }
        amountByCategory: { [key: string]: number }
        amount: number
      }
    },
  )

  return cartBrands
}

/** Gets disticts brands from cart
 * and for each brand calculates the number of items and the amount  */
export const getBrandsFromCartItems = (
  cartProducts: CartProduct[],
  brands: Brand[],
  productsDetails: Record<string, Product> | undefined,
) => {
  const cartBrands = cartProducts.reduce((result, product) => {
    const brand = getBrandByBrandCode(brands, product.brandCode)

    if (!brand) {
      return result
    }

    const brandCode: string = brand.code
    const { quantity, modelCode, colorCode, confirmedQuantity } = product
    const productDetails =
      productsDetails && productsDetails[modelCode] && productsDetails[modelCode].mocos[colorCode]
    const whsPrice = (productDetails && productDetails.whsPrice) || 0

    const totalQuantity = Math.abs(quantity) + Math.abs(confirmedQuantity || 0)

    const amount = whsPrice * totalQuantity

    // productDatails may not be loaded yet
    const category = productDetails
      ? ['2', 'K'].includes(productDetails.categoryId)
        ? 'sun'
        : 'optical'
      : 'unkown'

    if (!result[brandCode]) {
      result[brandCode] = {
        brand,
        numberOfItems: totalQuantity,
        amount,
        amountByCategory: {
          sun: 0,
          optical: 0,
          [category]: amount,
        },
        numberOfItemsByCategory: {
          sun: 0,
          optical: 0,
          [category]: totalQuantity,
        },
      }
    } else {
      result[brandCode].numberOfItems += totalQuantity
      result[brandCode].amount += amount
      result[brandCode].amountByCategory[category] += amount
      result[brandCode].numberOfItemsByCategory[category] += totalQuantity
    }

    return result
  }, {} as Record<string, { brand: Brand; numberOfItems: number; amount: number; amountByCategory: Record<string, number>; numberOfItemsByCategory: Record<string, number> }>)

  return cartBrands
}

/**
 * Counts products in cart for each door.
 * @param {Array} products Array of products from cart
 * @returns Object with door (key) – count (value) mapping
 */
export const countCartProductsByDoors = (cartProducts: CartProduct[]) => {
  const doors = cartProducts.reduce((result, cartProduct) => {
    result[cartProduct.doorId] = (result[cartProduct.doorId] || 0) + cartProduct.quantity
    return result
  }, {} as Record<string, number>)

  return doors
}

export const isActiveBrandInFilteredCart = (
  cartBrands: { brand: Brand }[],
  activeBrand: string | null,
) => cartBrands.find(brand => brand.brand.code === activeBrand)

export const MAXITEMS = 99999
export const getSafeValueAndQuantity = (value: number, quantity: number | string) => {
  const newQuantity = Number(quantity) + value

  let safeNewQuantity = newQuantity
  if (newQuantity > MAXITEMS) {
    safeNewQuantity = MAXITEMS
  }
  if (newQuantity < 0) {
    safeNewQuantity = 0
  }
  const safeValue = value - (newQuantity - safeNewQuantity)

  return { safeValue, safeNewQuantity }
}

const sortByInsertedAt = (
  a: { firstSizeInsertedAt: number | null },
  b: { firstSizeInsertedAt: number | null },
) =>
  b.firstSizeInsertedAt !== null && a.firstSizeInsertedAt !== null
    ? b.firstSizeInsertedAt - a.firstSizeInsertedAt
    : 0
const sortByShopDataOrder = (a: { shopDataOrder?: number }, b: { shopDataOrder?: number }) =>
  a.shopDataOrder !== undefined && b.shopDataOrder !== undefined
    ? a.shopDataOrder - b.shopDataOrder
    : 0
export const orderCartMocos = <
  T extends { firstSizeInsertedAt: number | null; shopDataOrder?: number }[]
>(
  cartMocos: T,
) => {
  return cartMocos.sort((a, b) => {
    const considerInsertedAt = a.firstSizeInsertedAt !== b.firstSizeInsertedAt
    return considerInsertedAt ? sortByInsertedAt(a, b) : sortByShopDataOrder(a, b)
  })
}

const isCartProductInRecommended = (recommendedMocos: Moco[], cartProducts: CartProduct[]) => {
  const recommendedUpcs = recommendedMocos.flatMap(r => Object.keys(r.sizes))

  return (cartProduct: Moco & { firstSizeInsertedAt: number; shopDataOrder?: number }) =>
    Object.keys(cartProduct.sizes).some(
      // the size is in both columns and in cart
      upc =>
        recommendedUpcs.includes(upc) && cartProducts.some(cartProduct => cartProduct.upc === upc),
    )
}

export const compareSkuRanks = (
  a: { skuBestSellerRanking?: { id: string } },
  b: { skuBestSellerRanking?: { id: string } },
) => parseInt(a.skuBestSellerRanking?.id || '') - parseInt(b.skuBestSellerRanking?.id || '')

export const orderBestSellerCartMocos = (
  cartMocos: (Moco & { firstSizeInsertedAt: number; shopDataOrder?: number })[],
  recommendedMocosToRender: (Moco & { firstSizeInsertedAt?: number; shopDataOrder?: number })[],
  cartProducts: CartProduct[],
) => {
  // Get last products added to cart from recommended column
  const lastProductsAddedToCart = cartMocos.filter(
    isCartProductInRecommended(recommendedMocosToRender, cartProducts),
  )

  // Sort by skuBestSellerRanking [ASC]
  const productsOrderedBySkuRank = cartMocos
    .filter(m => !lastProductsAddedToCart.includes(m))
    .sort((a, b) => {
      const considerSkuRank = a.skuBestSellerRanking?.id !== b.skuBestSellerRanking?.id
      return considerSkuRank ? compareSkuRanks(a, b) : sortByShopDataOrder(a, b)
    })

  // Put lastProductsAddedToCart on the top of items to render
  return [...lastProductsAddedToCart, ...productsOrderedBySkuRank]
}

export const countItemsInCart = (cartProductsFilteredByActiveDoors: CartProduct[]) =>
  cartProductsFilteredByActiveDoors.reduce((total, cartProduct) => total + cartProduct.quantity, 0)

export const countItemsProcessed = (cartProductsFilteredByActiveDoors: CartProduct[]) =>
  cartProductsFilteredByActiveDoors.reduce(
    (total, cartProduct) => total + (cartProduct.confirmedQuantity as number),
    0,
  )

export const deleteFromCart = ({
  productSizes,
  cartProducts,
  updateCart,
  brandCode,
}: {
  productSizes: Size[]
  cartProducts: CartProduct[]
  updateCart: (p: UpdateCartPayload) => void
  brandCode: string
}): void => {
  const doorCodesToDelete = cartProducts.reduce((result: string[], { doorId, quantity }) => {
    return quantity && !result.includes(doorId) ? [...result, doorId] : result
  }, [])
  updateCart({
    doors: doorCodesToDelete,
    productSizes: productSizes.map(productSize => ({ ...productSize, brandCode })),
    qntToAdd: 0,
    cartProducts,
    finalQuantity: 0,
  })
}

export const getDeliveryDate = (
  cartProductDeliveryDate: string | undefined,
  cartProductMinDeliveryDate: string | undefined,
  reversed?: boolean,
) => {
  const deliveryDateYYYYMMDD = cartProductDeliveryDate
    ?.split('-')
    .reverse()
    .join('-')
  const minDeliveryDateYYYYMMDD = cartProductMinDeliveryDate
    ?.split('-')
    .reverse()
    .join('-')

  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
  const deliveryDate = new Date(deliveryDateYYYYMMDD || '')
  const minDeliveryDate = new Date(minDeliveryDateYYYYMMDD || '')
  const isDeliveryDateValid = deliveryDate && !isNaN(deliveryDate.getDate())
  const isMinDeliveryDateValid = minDeliveryDate && !isNaN(minDeliveryDate.getDate())

  if (
    isDeliveryDateValid &&
    (deliveryDate >= minDeliveryDate || (!isMinDeliveryDateValid && deliveryDate >= tomorrow))
  ) {
    return reversed ? deliveryDateYYYYMMDD || '' : cartProductDeliveryDate || ''
  } else if (isMinDeliveryDateValid) {
    return reversed ? minDeliveryDateYYYYMMDD || '' : cartProductMinDeliveryDate || ''
  } else {
    return reversed
      ? `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${tomorrow
          .getDate()
          .toString()
          .padStart(2, '0')}`
      : `${tomorrow
          .getDate()
          .toString()
          .padStart(2, '0')}-${(tomorrow.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${tomorrow.getFullYear()}`
  }
}

export const emptyTagGroup = {
  bestsellers: [],
  futureTrends: [],
  adv: [],
}

export const isMocoInCart = (item?: MocoInCart | CartProduct): item is MocoInCart =>
  item !== undefined && 'sizes' in item

export const getLaterMinDeliveryDateFromCartProducts = (
  cartProducts: AfaCartProduct[],
  deliveryDate: DdMmYyyyDateString,
) => {
  const cartProductsWithMinDeliveryDate = cartProducts.filter(
    ({ minDeliveryDate }) => minDeliveryDate,
  )

  const productWithLaterMinDeliveryDate = cartProductsWithMinDeliveryDate
    .map(cartProduct => ({
      ...cartProduct,
      minDeliveryTimestamp: convertDdMmYyyyToDate(cartProduct.minDeliveryDate).getTime(),
    }))
    .sort((a, b) => a.minDeliveryTimestamp - b.minDeliveryTimestamp)[
    cartProductsWithMinDeliveryDate.length - 1
  ]

  const minDeliveryDate = productWithLaterMinDeliveryDate?.minDeliveryDate || deliveryDate

  return minDeliveryDate
}

export const getMaxSplits = (products: AfaCartProduct[], maxSplits: number = 3): number => {
  const sortFromHigherToLowerQty = products.sort(
    (a, b) => b.unconfirmedQuantity - a.unconfirmedQuantity,
  )

  if (!sortFromHigherToLowerQty?.length) return maxSplits

  if (sortFromHigherToLowerQty[0].unconfirmedQuantity > maxSplits) {
    return maxSplits
  } else {
    return sortFromHigherToLowerQty[0].unconfirmedQuantity - 1
  }
}
