import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetSingleWhiteboardQuery,
  useLazyGetKamsQuery,
  useShareTemplateMutation,
} from '../../../../services/whiteboard'
import Modal from '../Modal'
import { errorNotification } from '../../../../components/Notification/notifications'
import RCIcon from '../../../../components/UI/RCIcon'
import {
  Input,
  Button,
  Wrapper,
  Subtitle,
  Body,
  UserList,
  UserRow,
  UserData,
  UserName,
  UserEmail,
  CheckIconRound,
  PlusIconRound,
  SuccessUserList,
} from './style'
import { BlobProvider } from '@react-pdf/renderer'
import DownloadDocument from '../DownloadDocument'
import { WhiteboardType } from '../../../../model/whiteboard'
import useCheckIfImageNeedsProxy from '../../../../hooks/useCheckIfImageNeedsProxy'
import { convertToBase64 } from '../../../../libs/files'
import { useSelector } from 'react-redux'
import { environmentSelector } from '../../../../store/app/selectors'
import { customerNationalitySelector } from '../../../../store/customer/selectors'
import { useGetBrandsQuery } from '../../../../services/afa'

type Props = {
  setShareModalIsOpen: (e: boolean) => void
  id: string
}

type KamType = {
  guidUserProfile: string
  userName: string
  email: string
  upn: string
}

const WhiteboardModalShareTemplate: React.FC<Props> = ({ setShareModalIsOpen, id }) => {
  const { t } = useTranslation()
  const customerNationality = useSelector(customerNationalitySelector)
  const brandsQuery = useGetBrandsQuery()

  const defaultCurrency = brandsQuery.data?.[0].currency || ''

  const environment = useSelector(environmentSelector)

  const [getKams, getKamsResult] = useLazyGetKamsQuery()
  const [shareTemplate, shareTemplateResult] = useShareTemplateMutation()

  const [accountsToShare, setAccountsToShare] = useState<KamType[]>([])
  const [step, setStep] = useState<'search' | 'success'>('search')

  const whiteboardQuery = useGetSingleWhiteboardQuery('template', id)
  const whiteboard = whiteboardQuery.data as WhiteboardType

  const [images, setImages] = useState<Record<string, string>>({})

  const { fetchImage } = useCheckIfImageNeedsProxy()

  const getImage = (image: string) =>
    image?.indexOf('cdn-record-files-pi/') > -1 ? image.split('cdn-record-files-pi/')[1] : image

  const imagesToFetch = useMemo(() => {
    return whiteboard?.boards.flatMap(({ items }) => {
      return items.map(({ id, product }) => {
        return {
          id,
          url: product.image,
        }
      })
    })
  }, [whiteboard?.boards])

  useEffect(() => {
    if (imagesToFetch) {
      imagesToFetch.forEach(({ id, url }) => {
        fetchImage(getImage(url), environment).then(localUrl =>
          setImages(images => ({ ...images, [id]: localUrl })),
        )
      })
    }
  }, [environment, fetchImage, imagesToFetch])

  const selectKamToShare = (kam: KamType) => {
    setAccountsToShare([...accountsToShare, kam])
  }

  const deselectKamToShare = (kam: KamType) => {
    const updatedKamArray = accountsToShare.filter(({ email }) => email !== kam.email)
    setAccountsToShare(updatedKamArray)
  }
  const handleShare = async (blob: Blob) => {
    const kamsToShare = accountsToShare.map(({ email }) => email)
    if (accountsToShare.length > 0) {
      const pdf64 = await convertToBase64(blob)
      shareTemplate({
        key: id,
        kamsToShare,
        pdf64: pdf64.replace('data:application/octet-stream;base64,', ''),
      })
    }
  }

  useEffect(() => {
    if (shareTemplateResult?.data?.warnings.length) {
      shareTemplateResult.data.warnings.forEach(({ messageCode }) => {
        errorNotification({
          message: t('Errors.BackendCodes.Technical', {
            error_code: messageCode,
            interpolation: { escapeValue: false },
          }),
          duration: false,
        })
      })
    }
    if (shareTemplateResult.data?.result) {
      setStep('success')
    }
  }, [shareTemplateResult, t])

  const closeModal = () => {
    setStep('search')
    setAccountsToShare([])
    setShareModalIsOpen(false)
  }

  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState<KamType[]>([])

  const search = useCallback(
    (searchText: string) => {
      if (searchText.replaceAll(' ', '')) {
        getKams({ searchText })
      } else {
        setSearchResult([])
      }
    },
    [getKams],
  )

  useEffect(() => {
    const timerId = setTimeout(() => {
      search(searchText)
    }, 500)
    return () => {
      clearTimeout(timerId)
    }
  }, [search, searchText])

  useEffect(() => {
    if (getKamsResult?.data) {
      setSearchResult(getKamsResult.data.kams)
    }
  }, [getKamsResult])

  const isKamSelected = (kam: KamType) => !!accountsToShare.find(({ upn }) => upn === kam.upn)

  return (
    <Modal title={t('Whiteboard.shareModal.title')} onCancel={closeModal}>
      <Wrapper>
        {step === 'search' && (
          <>
            <Subtitle>{t('Whiteboard.shareModal.search.subtitle')}</Subtitle>
            <Body>
              <p>{t('Whiteboard.shareModal.search.text')}</p>
              <Input
                placeholder={t('Whiteboard.shareModal.search.inputPlaceholder')}
                onChange={e => {
                  setSearchText(e.currentTarget.value)
                }}
              />

              {searchResult.length > 0 && (
                <UserList>
                  {searchResult.map((kam: KamType) => (
                    <UserRow
                      key={kam.email}
                      onClick={() =>
                        isKamSelected(kam) ? deselectKamToShare(kam) : selectKamToShare(kam)
                      }
                    >
                      <UserData>
                        <UserName>{kam.userName}</UserName>
                        <UserEmail>{kam.email}</UserEmail>
                      </UserData>
                      {isKamSelected(kam) ? <CheckIconRound /> : <PlusIconRound />}
                    </UserRow>
                  ))}
                </UserList>
              )}
            </Body>

            <Button className="confirm">
              <BlobProvider
                document={
                  <DownloadDocument
                    whiteboard={whiteboard}
                    images={images}
                    customerNationality={customerNationality}
                    currency={defaultCurrency}
                  />
                }
              >
                {({ blob: _blob }) => {
                  // Trick for Safari Mobile: We create a new blob with a type different from 'application/pdf' to prevent it from opening in the browser instead of downloading
                  const blob = new Blob([_blob as Blob], { type: 'application/octet-stream' })
                  if (!blob) return null
                  return (
                    <div onClick={() => handleShare(blob)}>
                      <RCIcon type="icon-send" /> {t('Whiteboard.shareModal.search.confirm')}
                    </div>
                  )
                }}
              </BlobProvider>
            </Button>
          </>
        )}

        {step === 'success' && (
          <>
            <Subtitle>{t('Whiteboard.shareModal.success.subtitle')}</Subtitle>
            <SuccessUserList>
              {accountsToShare.map(({ email, userName }) => (
                <UserRow key={email}>
                  <UserData>
                    <UserName>{userName}</UserName>
                    <UserEmail>{email}</UserEmail>
                  </UserData>

                  <CheckIconRound />
                </UserRow>
              ))}
            </SuccessUserList>
            <Button onClick={closeModal} className="confirm">
              {t('Whiteboard.shareModal.success.close')}
            </Button>
          </>
        )}
      </Wrapper>
    </Modal>
  )
}

export default WhiteboardModalShareTemplate
